// frontend/src/pages/TransactionHistory.jsx

import React, { useEffect, useState } from 'react';
import { Container, Typography, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function TransactionHistory() {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('/transactions', {
          params: { initData: window.Telegram.WebApp.initData }
        });
        setTransactions(response.data.transactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <Container maxWidth="md" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>{t('transactionHistory')}</Typography>
      <List>
        {transactions.length > 0 ? (
          transactions.map((transaction) => (
            <ListItem key={transaction.txid}>
              {transaction.amount} FERMA - {transaction.date} ({transaction.type})
            </ListItem>
          ))
        ) : (
          <Typography variant="body2">{t('noTransactions')}</Typography>
        )}
      </List>
    </Container>
  );
}

export default TransactionHistory;
