// frontend/src/context/UserContext.js
import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userBalances, setUserBalances] = useState(null);
  const [userReferralEarnings, setUserReferralEarnings] = useState(null);
  const [config, setConfig] = useState(null);
  const [initData, setInitData] = useState(null);
  const [claimTimer, setClaimTimer] = useState(null);
  const [isMobile, setIsMobile] = useState(null);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userBalances,
        setUserBalances,
        userReferralEarnings,
        setUserReferralEarnings,
        config,
        setConfig,
        initData,
        setInitData,
        claimTimer, 
        setClaimTimer,
        isMobile, 
        setIsMobile
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
