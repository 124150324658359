import React, { lazy, Suspense, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext, UserProvider } from './context/UserContext';
import Header from './components/Header';
import BottomNav from './components/BottomNav';

import { Alert, Container, CircularProgress, Typography, Link, Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n';
import { useTranslation } from 'react-i18next';
import background from './assets/farm-background.png'; // Asigură-te că calea este corectă
import TransactionHistory from './pages/TransactionHistory'; // Importă componenta
//import isMobile from './utils/telegramPlatform';
import QRCode from 'qrcode.react';

const MainPage = lazy(() => import('./pages/MainPage.jsx'));
const ReferralsPage = lazy(() => import('./pages/ReferralsPage.jsx'));
const LevelUpBoost = lazy(() => import('./pages/LevelUpBoost.jsx'));
const SettingsPage = lazy(() => import('./pages/SettingsPage.jsx')); // Importăm SettingsPage
const TaskPage = lazy(() => import('./pages/TaskPage.jsx')); // Importăm SettingsPage
const TopUsersPage = lazy(() => import('./pages/TopUsersPage.jsx'));
const ReferralMarketplacePage = lazy(() => import('./pages/ReferralMarketplacePage.jsx'));
const DailyComboDetail = lazy(() => import('./pages/DailyComboDetail.jsx'));
const JumpingPig = lazy(() => import('./components/PigGame.jsx'));

function App() {
  const { setUser, setUserBalances, setUserReferralEarnings, setConfig, setInitData, setIsMobile } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.Telegram.WebApp.ready();
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
    }

    const initData = window.Telegram.WebApp.initData;

    if (!initData) {
      setError(
        <Container maxWidth="sm">
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h4" gutterBottom>
              {t('desktopAccessNotAllowed')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('pleaseUseMobileDevice')}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <QRCode value="https://t.me/FermaSosediBot/ferma" size={256} />
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('orUseLinkBelow')}
            </Typography>
            <Link href="https://t.me/FermaSosediBot/ferma" rel="noopener noreferrer" target="_blank">
              https://t.me/FermaSosediBot/ferma
            </Link>
          </Box>
        </Container>
      );
      setIsLoading(false);
      return;
    }

    setInitData(initData);

    const CheckIfMobile = (platform) => {
      return /android|ios/i.test(platform);
    };

    const fetchData = async () => {
      try {
        if (initData) {
          const response = await axios.post('/register', { initData }, { timeout: 5000 });
          setUser(response.data.user);
          setUserBalances(response.data.userBalances);
          setUserReferralEarnings(response.data.userReferralEarnings);

          setIsMobile(CheckIfMobile(window.Telegram.WebApp.platform || ''));

        } else {
          setError(
            <span>
              {t('errorLoadingUserData')}
              <Link href="https://t.me/FermaSosediBot" rel="noopener noreferrer">
                https://t.me/FermaSosediBot
              </Link>
            </span>
          );
        }
      } catch (error) {
        console.error('Error registering user:', error.response);
        setError(error.response?.data?.error || t('serverError'));
      }

      try {
        const configResponse = await axios.get('/config', { timeout: 5000 });
        setConfig(configResponse.data);
      } catch (error) {
        console.error('Error fetching config:', error);
        setError(t('configLoadingError'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [t, setUser, setUserBalances, setUserReferralEarnings, setConfig, setInitData, i18n, setIsMobile]);

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" component="div" ml={2}>
          {t('loading')}
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        {error}
      </Container>
    );
  }

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', minHeight: '100vh' }}>
      <Router>
        <Header />
        <Container maxWidth="md">
          {error && <Alert severity="error">{error}</Alert>}
          <Suspense fallback={<CircularProgress />}>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/level-up-boost" element={<LevelUpBoost />} />
              <Route path="/referrals" element={<ReferralsPage />} />
              <Route path="/tasks" element={<TaskPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/transaction-history" component={TransactionHistory} />
              <Route path="/top-users" element={<TopUsersPage />} />
              <Route path="/referral-marketplace" element={<ReferralMarketplacePage />} />
              <Route path="/daily-combo-detail" element={<DailyComboDetail />} />
              <Route path="/jumpingpig" element={<JumpingPig />} />
              <Route path="*" element={<MainPage />} />
            </Routes>
          </Suspense>
        </Container>
        <Container sx={{ marginBottom: 7 }}>
          <Box sx={{ textAlign: 'center' }}>
{/*            <Typography variant="body2">
              {t('fullGame')}:
            </Typography>
           <Typography variant="body2" sx={{ margin: 1 }}>
              <a href="https://fermasosedi.biz" target="_blank" rel="noopener noreferrer" className="footer-link">fermasosedi.biz</a>
            </Typography>*/}
            <Typography variant="body2">
              © 2024
            </Typography>
          </Box>
          <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', zIndex: 1 }}>
            <BottomNav />
          </Box>
        </Container>
      </Router>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
}
